body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
@font-face {
  font-family: 'Glober';
  src: local('Glober'), url(./fonts/globernormal.otf) format('opentype');
}
@font-face {
  font-family: 'GloberBlack';
  src: local('GloberBlack'), url(./fonts/globerx.otf) format('opentype');
}
@font-face {
  font-family: 'GloberSemiBold';
  src: local('GloberSemiBold'), url(./fonts/globersemibold.otf) format('opentype');
}
@font-face {
  font-family: 'KomikaAxis';
  src: local('KomikaAxis'), url(./fonts/KOMIKAX_.ttf) format('truetype');
}

::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: black;
  opacity: 1; /* Firefox */
}

:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: black;
}

::-ms-input-placeholder { /* Microsoft Edge */
  color: black;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
